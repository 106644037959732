import * as Sentry from '@sentry/vue';
import { computed, ref, watch } from '@vue/composition-api';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useJwt } from '@vueuse/integrations/useJwt';
import { differenceInMilliseconds, isPast } from 'date-fns';
import { gql } from 'graphql-tag';
import { JwtPayload as BaseJwtPayload } from 'jwt-decode';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { apollo, resetApollo } from '@/apollo';
import { ResourceType, Role } from '@/graphql/types';
import { CsrfTokenLink } from '@/lib/apollo/csrf-token';
import { GRAPHQL_HTTP } from '@/lib/env';
import { GqlKeyboardLayout } from '@/types/graphql';

const JWT_PAYLOAD_COOKIE = '__Secure-typetuin-app-jwt-payload';

export type AuthState =
  | 'unauthenticated'
  | 'initialising'
  | 'expired'
  | 'authenticated';

export const useAuthStore = defineStore('auth', () => {
  const cookies = useCookies([JWT_PAYLOAD_COOKIE]);
  const jwtPayloadCookie = computed(() => cookies.get(JWT_PAYLOAD_COOKIE));
  const { header: jwt } = useJwt<
    object,
    BaseJwtPayload & {
      role: Role;
      resource: {
        resource: ResourceType;
        resourceId: string;
      };
    }
  >(jwtPayloadCookie);
  const initialized = ref(false);
  const expiryTimeout = ref<NodeJS.Timeout | undefined>();
  const state = computed<AuthState>(() => {
    if (!jwt.value) {
      return 'unauthenticated';
    }

    if (!initialized.value) {
      return 'initialising';
    }

    if (!!jwt.value.exp && isPast(jwt.value.exp * 1_000)) {
      return 'expired';
    }

    return 'authenticated';
  });
  const role = computed(() => jwt.value?.role);

  if (jwt.value) {
    // Initially when setting up the store, check if we already have a JWT token and if so, refresh the token
    refresh();
  }

  async function registerAsDemoUser(keyboardLayout: GqlKeyboardLayout) {
    await apollo.defaultClient.mutate({
      mutation: gql`
        mutation RegisterDemoUser($keyboardLayout: KeyboardLayout) {
          registerDemoUser(data: { keyboardLayout: $keyboardLayout }) {
            user {
              id
              typetuin {
                id
              }
            }
          }
        }
      `,
      variables: { keyboardLayout },
      context: { skipQueue: true },
    });

    await resetApollo();
    initialized.value = true;
  }

  async function login({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    await apollo.defaultClient.mutate({
      mutation: gql`
        mutation Login($username: String!, $password: String!) {
          loginUser(data: { login: $username, password: $password }) {
            user {
              id
            }
          }
        }
      `,
      variables: { username, password },
      context: { skipQueue: true },
    });

    expiryTimeout.value = createTimeout();
    await resetApollo();
    initialized.value = true;
  }

  async function refresh() {
    await apollo.defaultClient.mutate({
      mutation: gql`
        mutation Refresh($userId: String!) {
          refreshToken(data: { userId: $userId }) {
            user {
              id
            }
          }
        }
      `,
      variables: { userId: jwt.value?.sub },
      context: { skipQueue: true },
    });

    initialized.value = true;
    expiryTimeout.value = createTimeout();
  }

  async function impersonate(userId: string) {
    await apollo.defaultClient.mutate({
      mutation: gql`
        mutation Impersonate($userId: ID!) {
          impersonateUser(data: { userId: $userId }) {
            user {
              id
            }
          }
        }
      `,
      variables: { userId },
    });

    expiryTimeout.value = createTimeout();
    await resetApollo();
    initialized.value = true;
  }

  async function logout(type: 'mutation' | 'cookie' = 'mutation') {
    CsrfTokenLink.csrfToken = undefined;
    Sentry.setUser(null);

    switch (type) {
      case 'cookie':
        cookies.remove(JWT_PAYLOAD_COOKIE, {
          domain: window.location.host.replace(/^(mijn|beheer)(\.local)?/, ''),
          secure: true,
        });
        break;
      case 'mutation':
      default:
        await fetch(`${GRAPHQL_HTTP?.replace(/\/graphql$/, '')}/auth/logout`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            query: gql`
              mutation Logout {
                logUserOut {
                  message
                }
              }
            `,
          }),
        });
        break;
    }

    clearTimeout(expiryTimeout.value);
    expiryTimeout.value = undefined;
    await resetApollo();
  }

  function createTimeout() {
    if (!jwt.value?.exp) {
      return;
    }

    return setTimeout(
      refresh,
      differenceInMilliseconds(jwt.value.exp * 1_000, Date.now()),
    );
  }

  watch(state, (value) => {
    switch (value) {
      case 'expired':
        refresh();
        return;
      default:
        return;
    }
  });

  return {
    jwt,
    state,
    role,
    registerAsDemoUser,
    login,
    refresh,
    impersonate,
    logout,
  };
});

if (module.hot) {
  module.hot.accept(acceptHMRUpdate(useAuthStore, module.hot));
}
