import { RetryLink as Link } from '@apollo/client/link/retry';
import { logger } from '@/logger';

export const RetryLink = new Link({
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      const willRetry = !!error;

      logger.getLogger('RetryLink').trace({ willRetry, error, operation });

      return willRetry;
    },
  },
  delay: {
    initial: 1_000,
    jitter: true,
    max: Infinity,
  },
});
