


































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { ApolloQueryResult } from '@apollo/client/core';
import type { ErrorResponse } from '@apollo/client/link/error';
import * as Sentry from '@sentry/vue';
import { defineComponent } from '@vue/composition-api';
import { gql } from 'graphql-tag';
import { mapStores } from 'pinia';
import Background from '@/components/Background.vue';
import DemoBorder from '@/components/DemoBorder.vue';
import DevMenu from '@/components/DevMenu.vue';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import IdleListener from '@/components/IdleListener.vue';
import ModalError from '@/components/ModalError.vue';
import ModalMessage from '@/components/ModalMessage.vue';
import Scene from '@/components/Scene.vue';
import Symbols from '@/components/SvgSymbols.vue';
import meQuery from '@/graphql/auth/queries/Me.gql';
import { setGraphqlErrorHandler } from '@/lib/apollo/error';
import { QueueLink } from '@/lib/apollo/queue';
import { APP_VERSION } from '@/lib/env';
import { date } from '@/lib/filters/date';
import { logger } from '@/logger';
import { useAuthStore } from '@/stores/auth';
import { useMainStore } from '@/stores/main';
import { GqlUser } from '@/types/graphql';
import { Role } from './graphql/types';

type ApolloResultKeyboardLayout = {
  me: Pick<GqlUser, 'id' | 'keyboardLayout'>;
};

export default defineComponent({
  name: 'TtApp',
  components: {
    DemoBorder,
    Background,
    DevMenu,
    Scene,
    Symbols,
    ErrorBoundary,
    IdleListener,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    // all titles will be injected into this template
    titleTemplate: 'Typetuin - %s',
  },
  data(): {
    version: string;
    scale: number;
    boundaryError: string;
    fadeElems: boolean;
    me: TODO | null;
    networkState: 'offline' | 'online';
  } {
    return {
      version: APP_VERSION,
      scale: 1,
      boundaryError: '',
      fadeElems: false,
      me: null,
      networkState: 'online',
    };
  },
  apollo: {
    me: {
      skip() {
        return this.authStore.state !== 'authenticated';
      },
      query: meQuery,
      update(data) {
        return data?.me;
      },
      result(result) {
        if (!result?.data?.me) {
          return;
        }

        Sentry.setUser({
          id: result.data.me.id,
          username: result.data.me.username,
        });
      },
      error() {
        Sentry.setUser(null);
      },
    },
    keyboardLayout: {
      skip() {
        return this.authStore.state !== 'authenticated';
      },
      query: gql`
        query MyKeyboardLayout {
          me {
            id
            keyboardLayout
          }
        }
      `,
      update(data: ApolloResultKeyboardLayout) {
        return data?.me?.keyboardLayout;
      },
      result(result: ApolloQueryResult<ApolloResultKeyboardLayout>) {
        this.mainStore.keyboardLayout = result?.data?.me?.keyboardLayout;
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  created() {
    // Set initial locale
    this.$i18n.locale = this.mainStore.locale;
  },
  async mounted() {
    logger.info('Typetuin Game version', this.version);
    this.$nextTick(() => {
      window.addEventListener('resize', this.setbackgroundScale);
      this.setbackgroundScale();
    });

    setGraphqlErrorHandler(this.handleGraphqlError);

    window.addEventListener('offline', this.handleNetwork);
    window.addEventListener('online', this.handleNetwork);
  },
  async destroyed() {
    window.removeEventListener('offline', this.handleNetwork);
    window.removeEventListener('online', this.handleNetwork);
  },
  computed: {
    ...mapStores(useMainStore, useAuthStore),
    hasDemoNotch(): boolean {
      return !!this.demoNotchPosition;
    },
    demoNotchPosition(): string | undefined {
      if (
        !(this.authStore.role === Role.DemoStudent) ||
        this.$route.meta.allowUnauthorized
      )
        return;

      if (this.$route.meta.demoNotchPosition)
        return this.$route.meta.demoNotchPosition;

      return 'top';
    },
    focusMode(): boolean {
      if (!this.me || !this.me.typetuin) return false;
      return (
        this.me.typetuin.focusMode &&
        ['Level Game', 'Exam Examination', 'Test Exam Examination'].includes(
          this.$route.name,
        )
      );
    },
    backgroundImage(): TODO | undefined {
      // use props to replace values in dynamicBackgroundImage
      if (
        this.$route.meta &&
        this.$route.meta.background &&
        this.$route.meta.background.dynamicBackgroundImage
      ) {
        const route = Object.keys(this.$route.params).reduce((prev, key) => {
          if (
            this.$route.meta.background.dynamicBackgroundImage.includes(
              `[${key}]`,
            )
          ) {
            return this.$route.meta.background.dynamicBackgroundImage.replace(
              `[${key}]`,
              this.$route.params[key],
            );
            // return prev;
          }
          return prev;
        }, '');
        return route;
      }

      if (
        !this.$route.meta ||
        !this.$route.meta.background ||
        !this.$route.meta.background.backgroundImage
      ) {
        return undefined;
      }
      return this.$route.meta.background.backgroundImage;
    },
    backgroundOpacity(): TODO | undefine {
      if (
        !this.$route.meta ||
        !this.$route.meta.background ||
        !this.$route.meta.background.backgroundOpacity
      ) {
        return undefined;
      }
      return this.$route.meta.background.backgroundOpacity;
    },
    routerClass(): TODO | undefined {
      if (!this.$route.meta || !this.$route.meta.routerClass) return undefined;
      return this.$route.meta.routerClass;
    },
    pauseGql(): boolean {
      return (
        this.networkState === 'offline' ||
        this.authStore.state !== 'authenticated'
      );
    },
  },
  watch: {
    backgroundOpacity(value) {
      if (value < 0.5) {
        this.fadeElems = true;
      } else {
        this.fadeElems = false;
      }
    },
    '$i18n.locale'(newValue) {
      // Sync $i18n to mainStore
      if (newValue !== this.mainStore.locale) this.mainStore.locale = newValue;
    },
    'mainStore.locale'(newValue) {
      // Sync mainStore to $i18n
      if (newValue !== this.$i18n.locale) this.$i18n.locale = newValue;
    },
    pauseGql: {
      handler(value: boolean) {
        switch (value) {
          case true:
            QueueLink.close();
            break;
          case false:
          default:
            QueueLink.open();
            break;
        }
      },
      immediate: true,
    },
  },
  methods: {
    demoInfo() {
      this.$nextTick(() => {
        this.$modal.show(
          ModalMessage,
          {
            title: 'Welkom in de Typetuin!',
            bodyText:
              'Leuk dat je een kijkje komt nemen! In deze demoversie kun je level 1, level 8 en één bonuslevel spelen. In de demoversie zijn niet alle functionaliteiten beschikbaar en ook je voortgang wordt niet bijgehouden, maar je kunt rustig even rondkijken. Wil je meer weten, ga dan naar <a target="_blank" href="https://typetuin.nl/aanmelden">typetuin.nl</a>',
            primaryText: 'Doorgaan',
          },
          {
            width: 600,
            height: 'auto',
          },
        );
      });
    },
    handleGraphqlError(error: ErrorResponse): void {
      logger.getLogger('GraphQL').error(error);

      if (error.graphQLErrors) {
        const handled = error.graphQLErrors
          .map((graphQLError) => {
            switch (graphQLError.message) {
              case 'Refresh token is missing':
              case 'Invalid Refresh Token':
                this.authStore
                  .logout('cookie')
                  .then(() => this.$router.replace({ name: 'Login' }));
                return true;
              case 'Invalid CSRF-token':
              case 'Missing CSRF-token (hash)':
                this.authStore.refresh();
                return true;
              case 'Rate limit exceeded':
                this.$modal.show(
                  ModalMessage,
                  {
                    title: 'Maximum aantal proeflessen vandaag bereikt.',
                    bodyText:
                      'Uit veiligheidsoverwegingen kun je per IP adres maximaal 5 proeflessen per dag doen.',
                    primaryText: 'OK',
                    primaryIcon: false,
                  },
                  {
                    height: 350,
                  },
                );
                return true;
              case 'Invalid Credentials':
                this.$modal.show(
                  ModalMessage,
                  {
                    title: 'Oeps.',
                    bodyText:
                      'Je gebruikersnaam en / of wachtwoord kloppen niet. Vul je juiste gebruikersnaam en wachtwoord in.',
                    primaryText: 'OK',
                    primaryIcon: false,
                  },
                  {
                    height: 350,
                  },
                );
                return true;
              case 'Password not set':
                this.$modal.show(
                  ModalMessage,
                  {
                    title: 'Registratie is nog niet voltooid',
                    bodyText:
                      'Voltooi eerst de stappen in de registratie e-mail die je ontvangen hebt, of vraag een beheerder je wachtwoord in te stellen.',
                    primaryText: 'OK',
                    primaryIcon: false,
                  },
                  {
                    height: 350,
                  },
                );
                return true;
              case 'Temp Password Exception':
                this.$modal.show(
                  ModalMessage,
                  {
                    title: 'Tijdelijk wachtwoord',
                    bodyText:
                      'Je hebt een tijdelijk wachtwoord. Je wordt naar een pagina geleid om dit naar een wachtwoord naar keuze te veranderen.',
                    primaryText: 'OK',
                    primaryIcon: false,
                    primaryCallback() {
                      this.$router.push({
                        name: 'NewPassword',
                        query: {
                          token:
                            graphQLError.extensions.exception.response.token,
                          user_id:
                            graphQLError.extensions.exception.response.userId,
                        },
                      });
                    },
                  },
                  {
                    width: 600,
                    height: 'auto',
                  },
                );
                return true;

              default:
                logger
                  .getLogger('GraphQL')
                  .error('Unhandled GraphQLError', graphQLError);
                return false;
            }
          })
          .some((value) => value === true);

        if (handled) {
          return;
        }

        const status =
          error.graphQLErrors[0].extensions &&
          error.graphQLErrors[0].extensions.exception
            ? error.graphQLErrors[0].extensions.exception.status
            : null;
        const { query, code, exception } = error.graphQLErrors[0].extensions;
        const { message } = error.graphQLErrors[0];
        const errorCode = exception.response.error;

        logger.debug(error.graphQLErrors[0].extensions);
        logger.debug(
          'error message:',
          message,
          'error status',
          status,
          'code',
          code,
          'error code',
          errorCode,
        );

        logger.debug({
          code,
          errorCode,
        });

        const errorMap = {
          // START_DATE_IN_FUTURE: 'De startdatum van de cursus ligt in de toekomst. Probeer het later nog eens.',
          END_DATE_IN_PAST:
            'De eindatum van je cursus is verstreken. Je kunt niet meer inloggen.',
          NO_LICENSE:
            'Er is op dit moment geen licentie gekoppeld aan je account. Neem contact op met de beheerder.',
          SCHOOL_DEACTIVATED: 'De school is niet meer actief',
        };

        if (errorCode === 'START_DATE_IN_FUTURE') {
          logger.debug(error);
          return this.$modal.show(
            ModalMessage,
            {
              title: 'Startdatum in de toekomst.',
              bodyText: `De startdatum van de cursus ligt in de toekomst. Je kunt inloggen vanaf${
                exception.startDate
                  ? ` ${date(new Date(exception.startDate))}`
                  : ''
              }.`,
              primaryText: 'OK',
              primaryIcon: false,
            },
            {
              height: 350,
            },
          );
        }

        if (errorMap[errorCode]) {
          return this.showErrorModal(errorMap[errorCode]);
        }
        if (errorMap[code]) {
          return this.showErrorModal(errorMap[code]);
        }

        if (message === 'Invalid token') {
          return this.showErrorModal(
            'Het lijkt er op dat de token ongeldig is of al gebruikt is. Als er al een wachtwoord is aangemaakt kun je hiermee inloggen. Als dit probleem zich blijft voordoen neem dan contact op met Typetuin.',
          );
        }

        if (message === 'Invalid license') {
          return this.showErrorModal(
            'Het lijkt er op dat je geen gekoppelde licentie hebt, of dat deze is verlopen. Neem contact op met je school of met Typetuin.',
          );
        }

        if (status === 403) {
          if (message.includes('Not allowed just yet')) {
            const date = message.split('date: ')[1];
            return this.showErrorModal(
              `Je account is nog niet actief, vanaf ${date(
                new Date(date),
                'dd-MM-yyyy',
              )} kun je inloggen`,
              false,
            );
          }
        }

        logger.error(message);

        if (
          message ===
          'Unexpected error value: "The challenge request has been expired."'
        ) {
          return false;
        }

        switch (status) {
          case 404:
            if (query === '{me{__typename id}}') {
              logger.debug(
                'User not found. This can happen when you have a valid token, but the user is not in current database',
              );
            }
          // eslint-disable-next-line no-fallthrough
          default:
            return this.showErrorModal(message);
        }
      }

      if (error.networkError) {
        logger.error('Network error', {
          error: JSON.stringify(error),
          networkError: JSON.stringify(error.networkError),
          operation: JSON.stringify(error.operation),
          operationName: error.operation.operationName,
          variables: JSON.stringify(error.operation.variables),
        });

        if (error.networkError.message === 'Failed to fetch') {
          logger.info('Network error: Failed to fetch');
          return this.showErrorModal(
            'Het lijkt erop dat er geen connectie kan worden gemaakt met de server. Controleer uw internetverbinding en probeer het opnieuw.',
          );
        }

        if (RegExp(/5[0-9][0-9]/).test(String(error.networkError.statusCode))) {
          logger.info('Network error: 5xx');
          return this.showErrorModal(
            `Het lijkt erop dat er problemen zijn met de server. Probeer het later nog eens. ${error.networkError}`,
          );
        }

        return this.showErrorModal(error.networkError.message);
      }

      return false;
    },
    setbackgroundScale() {
      const windowWidth = document.documentElement.clientWidth;
      const windowHeight = document.documentElement.clientHeight;

      let calculatedScale = Math.min(windowWidth / 1280, windowHeight / 960);
      // only scale down?
      if (calculatedScale > 1.2) calculatedScale = 1.2;
      this.scale = calculatedScale;
    },
    showErrorModal(message, showContactInfo = true) {
      this.$modal.hide('errorModal');
      // this.boundaryError = message;
      this.$modal.show(
        ModalError,
        {
          error: message,
          showContactInfo,
        },
        {
          width: 600,
          height: 'auto',
          name: 'errorModal',
        },
      );
    },
    handleBoundaryError(error) {
      logger.getLogger('Boundary').error(error);

      // Handle GraphQL and Network errors seperately in graphqlErrorHandler
      if (
        error.err.message.includes('GraphQL error') ||
        error.err.message.includes('Network error') ||
        error.err.message ===
          'Store reset while query was in flight (not completed in link chain)'
      ) {
        return;
      }

      this.showErrorModal(error.err);
    },
    handleNetwork() {
      switch (navigator.onLine) {
        case true:
        default:
          this.$modal.hide('offlineModal');
          this.networkState = 'online';
          return;
        case false:
          this.networkState = 'offline';
          return;
      }
    },
  },
});
